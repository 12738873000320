import { useEffect, useState } from 'react';

export function useClickPreventionOnDoubleClick(): [boolean, (value: boolean) => void] {
    const [submitted, setSubmitted] = useState(false);

    useEffect((): void => {
        if (submitted) {
            setTimeout((): void => {
                setSubmitted(false);
            }, 2000);
        }
    }, [submitted]);

    return [submitted, setSubmitted];
}
