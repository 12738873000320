import React from 'react';
import { Button, BasicButtonProps } from '../A11y/Button';
import classNames from 'clsx';

/**
 * A button with no icon & uppercase text, with a offset/stroke effect when hovered.
 * Customize its look&feel by using the `className` prop and
 * custom CSS for &:disabled and &:hover styles.
 */
export interface OffsetButtonProps extends BasicButtonProps {
    /**
     * If passed as true, the text content of the button will not be wrapped.
     * Default to false, meaning that the button text will be wrapped.
     */
    noWrap?: boolean;
}

export function OffsetButton(props: OffsetButtonProps): JSX.Element {
    const buttonProps: BasicButtonProps = {
        ...props,
        className: classNames('OffsetButton', props.className, {
            'OffsetButton-NoWrap': !!props.noWrap,
        }),
        textClassName: classNames('OffsetButton-Text', props.textClassName, {
            'OffsetButton-Text-NoWrap': !!props.noWrap,
        }),
    };
    return <Button {...buttonProps} />;
}
