import { createPreStyledButton } from './ButtonUtils';
import { BasicButtonProps, ButtonProps } from '../A11y/Button';

export { getTitleProps } from './ButtonUtils';

/**
 * A plain/primary "Action" button with no icon & uppercase text.
 * Customize its look&feel by using the `className` prop and
 * custom CSS for &:disabled and &:hover styles.
 */
export type ActionButtonProps = BasicButtonProps &
    Pick<ButtonProps, 'icon' | 'iconPosition' | 'iconClassName' | 'customIconPath'>;
export const ActionButton: React.FunctionComponent<ActionButtonProps> = createPreStyledButton('ActionButton');
import './ActionButton.scss';

/**
 * A button with no icon & uppercase text, with a offset/stroke effect when hovered.
 * Customize its look&feel by using the `className` prop and
 * custom CSS for &:disabled and &:hover styles.
 */
export * from './OffsetButton';
import './OffsetButton.scss';

/**
 * A button that looks like an underlined link but that is indeed a
 * button which... :
 *  - opens a popup/modal when clicked (set ariaHasPopup prop to AriaRole.DIALOG)
 *  - or expand some section to show more content... (set ariaExpanded prop accordingly). Note that for this
 *    case, you can also consider using the ToggleButton which allows to add an icon.
 * ... to see more details about something
 * If needed, customize its look&feel by using the `className` prop and
 * custom CSS for &:disabled and &:hover styles.
 */
export type ShowMoreButtonProps = BasicButtonProps & Pick<ButtonProps, 'ariaExpanded'>;
export const ShowMoreButton: React.FunctionComponent<ShowMoreButtonProps> = createPreStyledButton('ShowMoreButton');
import './ShowMoreButton.scss';

export * from './ToggleButton';
