import React, { useState } from 'react';
import { BasicButtonProps, ButtonProps, Button } from '../A11y/Button';
// eslint-disable-next-line import/no-unresolved
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { useToggleState } from '../../../hooks';
import classNames from 'clsx';
import isFunction from 'lodash/isFunction';

/**
 * A Toggle Button button that holds its state (is that a good idea ?) and notify its
 * consumer thanks to a onToggle callback prop.
 * - You can have different texts for the 3 following states : OFF, ON, ON+HOVERED (see props below)
 * - You can have 2 different icons for the 2 states : OFF & ON
 * - You can customize its styling by passing extra CSS classnames using the following props
 * (all optional): className & textClassName & iconClassName.
 */

export type ToggleButtonProps = Omit<BasicButtonProps, 'text' | 'onClick' | 'ariaHasPopup'> &
    Pick<ButtonProps, 'iconPosition' | 'iconClassName'> & {
        /**
         * The INITIAL state of the ToggleButton. Defaults to false (switched off)
         */
        isInitiallySwitchedOn?: boolean;

        /**
         * The Icon to show when the toggle button is OFF.
         */
        offStateIcon?: IconDefinition;

        /**
         * The Icon to show when the toggle button is ON.
         * If not specified the offStateIcon will be used.
         */
        onStateIcon?: IconDefinition;

        /**
         * The (action) text label and title tooltip to show when the toggle button is OFF.
         */
        offStateText: string;

        /**
         * The (status) text label and title tooltip to show when the toggle button is ON.
         * If not specified the offStateText will be used.
         */
        onStateText?: string;

        /**
         * The (action) text label and title tooltip to show when the toggle button is ON and
         * it is hovered.
         * If not specified the onStateText will be used.
         */
        onStateHoveredText?: string;

        /**
         * Called when the ToggleButton is ... toggled.
         */
        onToggle?: (isSwitchedOn: boolean) => void;

        /**
         * If set to true, the ToggleButton will have no text, only the icons (make sure you pass onStateIcon and
         * offStateIcon props!).
         * The offStateText, onStateText, onStateHoveredText values will still be used for the native title tooltip
         * Defaults to false, meaning the label is shown by default.
         */
        noLabel?: boolean;

        onHover?: () => void;

        onBlur?: () => void;
    };

function getText(isPressed: boolean, isHovered: boolean, props: ToggleButtonProps): string {
    let text: string = props.offStateText;
    if (isPressed) {
        text = props.onStateText || text;
        if (isHovered) {
            text = props.onStateHoveredText || text;
        }
    }
    return text;
}

export function ToggleButton(props: ToggleButtonProps): JSX.Element {
    const [state, toggle] = useToggleState(props.isInitiallySwitchedOn);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    function handleClick(): void {
        toggle();
        if (isFunction(props.onToggle)) {
            props.onToggle(state);
        }
    }

    function handleMouseOverOrFocus(): void {
        setIsHovered(true);
        if (props.onHover) {
            props.onHover();
        }
    }

    function handleMouseOutOrBlur(): void {
        setIsHovered(false);
        if (props.onBlur) {
            props.onBlur();
        }
    }

    const text = getText(state, isHovered, props);

    const buttonProps: ButtonProps = {
        ...props,
        text: props.noLabel !== true ? text : undefined,
        tooltip: text,
        onClick: handleClick,
        className: classNames('ToggleButton', props.className),
        textClassName: classNames('ToggleButton-Text', props.textClassName),
        iconClassName: classNames('ToggleButton-Icon', props.iconClassName),
        ariaPressed: state,
        icon: state && props.onStateIcon != null ? props.onStateIcon : props.offStateIcon,
        onMouseOverOrFocus: handleMouseOverOrFocus,
        onMouseOutOrBlur: handleMouseOutOrBlur,
    };

    return <Button {...buttonProps} />;
}
