/* eslint-disable jsx-a11y/no-autofocus */
import React, {
    MouseEventHandler,
    MouseEvent,
    HTMLAttributes,
    ReactEventHandler,
    useImperativeHandle,
    useRef,
    forwardRef,
    KeyboardEventHandler,
} from 'react';
import AriaRole from '../../../AriaRole';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import classNames from 'clsx';
// eslint-disable-next-line import/no-unresolved
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * A generic button, that :
 * 1) Follows a11y guidelines defined in https://www.w3.org/TR/wai-aria-practices-1.1/#button
 * 2) Reset/clear default styles applied by browsers on buttons.
 * 3) Has no predefined styles applied, but allows the consumer to style it by using the
 * `className`, `textClassName` and `iconClassName` props
 * 4) Allows you to have a FontAwesome icon in the button.
 */

export interface BasicButtonProps extends React.RefAttributes<ButtonHandle> {
    /**
     * Text label in the button
     */
    text?: JSX.Element | string;

    /**
     * Whether or not the button is disabled.
     * Default to false
     */
    disabled?: boolean;

    /**
     * Indicates the availability and type of interactive popup element, such as menu or dialog, that can be triggered by this button.
     * A popup element usually appears as a block of content that is on top of other content. You MUST ensure that the role of the
     * element that serves as the container for the popup content is menu, listbox, tree, grid, or dialog, and that the value of
     * aria-haspopup matches the role of the popup container.
     *
     * So you should set the value of aria-haspopup to the same value as the role attribute on the triggered element. If set to
     * true it will be interpreted as menu to align with the 1.0 spec where aria-haspopup was only meant for menus.
     *
     * Defaults to false.
     */
    ariaHasPopup?: boolean | AriaRole.MENU | AriaRole.LISTBOX | AriaRole.TREE | AriaRole.GRID | AriaRole.DIALOG;

    /**
     * Called when the button has been clicked
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;

    /**
     * Called on key down when focus is on the button
     */
    onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;

    /**
     * When set to true, the button is not focusable, even programatically!
     * Defaults to false.
     */
    notFocusable?: boolean;

    /**
     * This Boolean attribute specifies that the button should have input focus when the page loads.
     * (Warning: If innapropriately used, can steal focus. It goes against the jsx-a11y/no-autofocus for instance.
     * So to be used with caution)
     */
    dangerouslyAutoFocus?: boolean;

    /**
     * optional prop which is used to style the button according to the
     * consumer requirements by adding a class to it
     */
    className?: string;

    /**
     * set style to text element
     */
    textClassName?: string;

    /**
     * The tooltip
     */
    tooltip?: string;

    id?: string;

    type?: 'button' | 'submit' | 'reset';

    'aria-hidden'?: boolean;
}

export interface ButtonProps extends BasicButtonProps {
    /**
     * Indicates whether the element, or another grouping element it controls, is currently expanded or collapsed.
     * Defaults to false.
     */
    ariaExpanded?: boolean;

    /**
     * Whether this button is a toggle button or not.
     *  - If ariaPressed is left undefined (default), the button is not a toggle button.
     *  - If ariaPressed is true, the button is a toggle button that is currently pressed.
     *  - If ariaPressed is false, the button is a toggle button that is currently not pressed.
     */
    ariaPressed?: boolean;

    /**
     * Identifies the element whose contents are controlled by the button.
     * Should be the id assigned to said element.
     */
    ariaControls?: string;

    /**
     * The optional icon to render in the button, next to the text label.
     */
    icon?: IconDefinition;

    /**
     * The icon position. Defaults to 'left'
     */
    iconPosition?: 'left' | 'right';

    /**
     * Optional extra CSS classnames to pass to style the icon.
     */
    iconClassName?: string;

    /**
     * Optional callback called when the button is hovered or
     * gets focused.
     */
    onMouseOverOrFocus?: ReactEventHandler<HTMLButtonElement>;

    /**
     * Optional callback called when the button is not hovered
     * anymore or looses focus.
     */
    onMouseOutOrBlur?: ReactEventHandler<HTMLButtonElement>;

    customIconPath?: string;
}

const defaultProps: Omit<ButtonProps, 'text'> = {
    onClick: noop,
    onKeyDown: noop,
    notFocusable: false,
    ariaHasPopup: false,
    dangerouslyAutoFocus: false,
    ariaExpanded: undefined,
    ariaPressed: undefined,
    ariaControls: undefined,
};

function getContainerProps(props: ButtonProps): HTMLAttributes<HTMLElement> {
    const className: string = classNames('Button', props.className);

    function handleClickOnButton(event: MouseEvent<HTMLButtonElement>): void {
        if (isFunction(props.onClick) && !props.disabled) {
            props.onClick(event);
        }
    }

    return {
        className,
        onClick: handleClickOnButton,
        onKeyDown: props.onKeyDown,
        title: props.tooltip || (typeof props.text === 'string' ? props.text : ''),
        'aria-hidden': props['aria-hidden'],
    };
}

function renderText(props: ButtonProps): JSX.Element | null {
    if (isEmpty(props.text)) {
        return null;
    }
    const className: string = classNames('Button-Text', props.textClassName);
    return <span className={className}>{props.text}</span>;
}

function renderIcon(props: ButtonProps): JSX.Element | null {
    if (props.icon === undefined && props.customIconPath === undefined) {
        return null;
    }

    const className: string = classNames(
        'Button-Icon',
        {
            'Button-Icon-Right': props.iconPosition === 'right',
        },
        props.iconClassName
    );

    if (props.icon !== undefined) {
        return <FontAwesomeIcon className={className} size="1x" aria-hidden="true" icon={props.icon} />;
    }

    return <img src={props.customIconPath} className={className} alt={props.customIconPath} aria-hidden="true" />;
}

export interface ButtonHandle {
    focus: () => void;
    getBoundingClientRect(): DOMRect | ClientRect;
    getDomElement(): HTMLButtonElement | null;
}
export const Button: React.ForwardRefExoticComponent<ButtonProps> = forwardRef<ButtonHandle, ButtonProps>(
    (props, ref): JSX.Element => {
        const textElement: JSX.Element | null = renderText(props);
        const iconElement: JSX.Element | null = renderIcon(props);
        const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);

        useImperativeHandle(
            ref,
            (): ButtonHandle => ({
                focus: (): void => {
                    if (buttonRef.current) {
                        buttonRef.current.focus();
                    }
                },
                getBoundingClientRect: (): DOMRect => {
                    if (buttonRef.current) {
                        return buttonRef.current.getBoundingClientRect();
                    }
                    const rect: any = { height: 0, width: 0, bottom: 0, left: 0, right: 0, top: 0 };
                    return rect;
                },
                getDomElement: (): HTMLButtonElement | null => buttonRef.current,
            })
        );

        let content: JSX.Element;
        if (props.iconPosition === 'right') {
            content = (
                <>
                    {textElement}
                    {iconElement}
                </>
            );
        } else {
            content = (
                <>
                    {iconElement}
                    {textElement}
                </>
            );
        }

        if (props.notFocusable) {
            // Simply render a <div>, not even a <button> so that we're sure it is not focusable
            return <div {...getContainerProps(props)}>{content}</div>;
        }

        return (
            <button
                ref={buttonRef}
                id={props.id}
                onMouseOver={props.onMouseOverOrFocus}
                onFocus={props.onMouseOverOrFocus}
                onMouseOut={props.onMouseOutOrBlur}
                onBlur={props.onMouseOutOrBlur}
                autoFocus={props.dangerouslyAutoFocus}
                tabIndex={props.disabled ? -1 : undefined}
                aria-expanded={props.ariaExpanded}
                aria-haspopup={props.ariaHasPopup}
                aria-pressed={props.ariaPressed}
                aria-controls={props.ariaControls}
                type={props.type}
                disabled={props.disabled}
                {...getContainerProps(props)}
            >
                {content}
            </button>
        );
    }
);

Button.displayName = 'Button';
Button.defaultProps = defaultProps as any;
