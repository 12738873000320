import React, { HTMLAttributes, FunctionComponent, forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, BasicButtonProps, ButtonHandle } from '../A11y/Button';
import classNames from 'clsx';

/**
 * Given the label, tooltip and the aria-label you want to use for a button,
 * returns the `title` and `aria-label` attributes to be set on the <button>
 *
 * @param  {string} label the text label of the button (what is shown on the screen :)
 * @param  {string} title the tooltip content (useful when the button only has an icon and no label)
 * @param  {string} ariaLabel
 * @returns title and aria-label attributes to be set on the <button>
 */
export function getTitleProps({
    label,
    title,
    ariaLabel,
}:
    | {
          label?: string; // at least one of label of title MUST be passed
          title: string;
          ariaLabel?: string;
      }
    | {
          label: string;
          title?: string;
          ariaLabel?: string;
      }): Pick<HTMLAttributes<HTMLButtonElement>, 'title' | 'aria-label'> {
    const titleProp: string = (title || label)!;

    // a button without a text label needs an aria-label of some sort on VoiceOver and TalkBack screen readers
    let ariaLabelProp: string | undefined = ariaLabel;
    if (isEmpty(label) && isEmpty(ariaLabel)) {
        ariaLabelProp = titleProp;
    }

    return {
        title: titleProp,
        'aria-label': ariaLabelProp,
    };
}

/**
 * Creates a Button component with predefined styling.
 * @param componentName the displaynName of the component, but also the main CSS classname
 */
export function createPreStyledButton(componentName: string): FunctionComponent<BasicButtonProps> {
    const functionComponent: FunctionComponent<BasicButtonProps> = forwardRef<ButtonHandle, BasicButtonProps>(
        (props, ref): JSX.Element => {
            const buttonProps: BasicButtonProps = {
                ...props,
                className: classNames(componentName, props.className),
                textClassName: classNames(`${componentName}-Text`, props.textClassName),
            };
            return <Button {...buttonProps} ref={ref} />;
        }
    );
    functionComponent.displayName = componentName;
    return functionComponent;
}
