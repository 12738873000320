import { useCallback, useState, useRef } from 'react';

// Usage
// function App() {
//     const [hoverRef, isHovered] = useHover();

//     return (
//         <div ref={hoverRef}>
//             {isHovered ? '😁' : '☹️'}
//         </div>
//     );
// }

export function useHover(): [(node: Element | null) => void, boolean] {
    const [value, setValue] = useState(false);

    // Wrap in useCallback so we can use in dependencies below
    const handleMouseEnter = useCallback<EventListener>((): void => {
        setValue(true);
    }, []);
    const handleMouseOut = useCallback<EventListener>((): void => {
        setValue(false);
    }, []);

    // Keep track of the last node passed to callbackRef
    // so we can remove its event listeners.
    const ref = useRef<Element>();

    // Use a callback ref instead of useEffect so that event listeners
    // get changed in the case that the returned ref gets added to
    // a different element later. With useEffect, changes to ref.current
    // wouldn't cause a rerender and thus the effect would run again.
    const callbackRef = useCallback(
        (node: Element | null): void => {
            if (ref.current) {
                ref.current.removeEventListener('mouseenter', handleMouseEnter);
                ref.current.removeEventListener('mouseout', handleMouseOut);
            }

            ref.current = node || undefined;

            if (ref.current) {
                ref.current.addEventListener('mouseenter', handleMouseEnter);
                ref.current.addEventListener('mouseout', handleMouseOut);
            }
        },
        [handleMouseEnter, handleMouseOut]
    );

    return [callbackRef, value];
}
