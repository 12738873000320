import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const getWindowDimension = (dimension: 'innerWidth' | 'innerHeight'): number | undefined => {
    return typeof window === 'object' ? window[dimension] : undefined;
};

function createUseWindowDimensionHook(dimension: 'innerWidth' | 'innerHeight'): () => number | undefined {
    return (): number | undefined => {
        const [windowDimension, setWindowDimension] = useState<number | undefined>(getWindowDimension(dimension));
        useEffect((): (() => void) => {
            const handleResize = (): void => setWindowDimension(getWindowDimension(dimension));
            handleResize();
            const handleResizeDebounced = debounce(handleResize, 100);
            window.addEventListener('resize', handleResizeDebounced);
            return (): void => {
                window.removeEventListener('resize', handleResizeDebounced);
            };
        }, []);
        return windowDimension;
    };
}

export const useWindowWidth: () => number | undefined = createUseWindowDimensionHook('innerWidth');
export const useWindowHeight: () => number | undefined = createUseWindowDimensionHook('innerHeight');
